// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dziekujemy-za-platnosc-tsx": () => import("./../src/pages/dziekujemy-za-platnosc.tsx" /* webpackChunkName: "component---src-pages-dziekujemy-za-platnosc-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-konsultacje-ksiegowe-tsx": () => import("./../src/pages/konsultacje-ksiegowe.tsx" /* webpackChunkName: "component---src-pages-konsultacje-ksiegowe-tsx" */),
  "component---src-pages-konsultacje-podatkowe-prawne-tsx": () => import("./../src/pages/konsultacje-podatkowe-prawne.tsx" /* webpackChunkName: "component---src-pages-konsultacje-podatkowe-prawne-tsx" */),
  "component---src-pages-szkolenie-tsx": () => import("./../src/pages/szkolenie.tsx" /* webpackChunkName: "component---src-pages-szkolenie-tsx" */)
}

